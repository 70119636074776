@use '../../customs' as *;
@use '../../componenets/mixins'as *;
@use '../../componenets/buttons';
@use '../../componenets/typography';
@import '../../fontawesome.scss';

.mx-auto{
    width: 100px;
    height: 3px;
}

.testimonials{
    position: relative;
    padding: 0;
    @include gradient;

    &__card{
        background-color: rgba($white,0.8);
        padding: 2rem 1.25rem;
        border-radius: 0.625rem;
        height: auto;
        text-align: center;  
        
        i{
            font-size: 1.5rem;
            color: rgba($primary,0.7);
        }

        .ratings{
            i{
                color: rgba($primary,0.7);
                font-size: 1rem;
            }
        }
    }

    // &__picture{
    //     height: 6rem;
    //     width: 5rem;
    //     position: absolute;
    //     left: 50%;
    //     top:13rem;
    //     transform: translate(-50%,-50%);

    // }

    &__name{
        padding-top: 1rem;
        margin-bottom: 1rem;
        text-align: center;
        font-weight: 500;
        color: white;

        h3{
            padding-top: 0.8rem;
            text-transform: capitalize;

        }

        p{
            padding-bottom: 2rem;
            text-transform: capitalize;
        }
    }
}