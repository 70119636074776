@use "../../componenets/typography";
@use '../../customs' as *;
@use '../../componenets/mixins'as *;
@use '../../componenets/animations'as *;
@import '../../fontawesome.scss';


.footer{
    padding-top: 1rem;
    background-color: $footer;
}



.contact-box{
    position: relative;

    &__icon{
        width: 2rem;
        stroke: $primary;
        padding-top: 0.5rem;
        @include media-breakpoint-up(sm){
            width: 4rem;
        }
    }

    &__info{
        padding: 0.4rem;
        font-weight: 600;


        &--title{
            color: white;
            text-decoration: none;
            font-size: 1rem;
            @include media-breakpoint-up(sm){
                font-size: 1.4rem;
            }

        }

        &--subtitle{
            color: $gray-600;
            text-decoration: none;
            font-size: 0.8rem;
            @include media-breakpoint-up(sm){
               font-size: 1rem;
            }
        }
    }
}


//STYLE THE SOCIAL MEDIA CONTENT
.footer-sm{
    background-color: #212121;

    a{
        color: white;
        i{
            font-size: 1rem;
            margin-left: 1rem;

            @include media-breakpoint-up(sm){
                font-size: 1.6rem;
             }

        }
    }
}

.map-container{
    max-width: 100%;
    max-height: 40rem;

}


//STYLE TH ECOPYRIGHT CONTENT

.footer-bottom{
    background-color: black;

    &__copyright{
        color: $gray-600;
        font-weight: 400;

        a{
            color: $gray-300;
            text-decoration: none;

            &:hover{
                color: $primary;
            }
        }
    }
}