@use '../../customs' as *;
@use '../../componenets/mixins'as *;
@use '../../componenets/buttons';
@use '../../componenets/typography';
@import '../../fontawesome.scss';



.navbar-title-text{
    @include media-breakpoint-down(sm){
        font-size: small
    }
}

.menu {
    @include gradient;
    // background:linear-gradient(to right,rgba(#ff7f50,0.95),rgba(#ff7f50,0.95));
    li{
        padding: 0 0.7rem;
        a{ 
            text-transform: capitalize;
            font-weight: 600;
        }
    }
}