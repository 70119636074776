@use "../../componenets/typography";
@use '../../customs' as *;
@use '../../componenets/mixins'as *;
@use '../../componenets/animations'as *;

.portfolio{
    padding: 4rem 0;
    .items-container{
        margin-bottom: 1.3rem;
    }
    .portfolio-box{
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 1rem;

        img{
            border-radius: 1rem;
        }


        .portfolio-info{
            h4{
                color: black;
            }
            .caption{
                color: rgb(61, 40, 40);               
            }
            button{
                background-color: lightgray;
                
            }            
        }

    }
}
.rakhi{
    @include media-breakpoint-up(sm){
        padding: 2rem;        
     }
}