@use "../../componenets/typography";
@use '../../customs' as *;
@use '../../componenets/mixins'as *;
@use '../../componenets/animations'as *;

.about-image{
    border-radius: 1rem;
}

.about{
    position: relative;
    padding: 1rem 0;

    &__content{
        padding: 1rem;
        margin-top: 1.5rem;
        &-text{
            margin-top: 3rem;
            padding: 2rem;
            @include media-breakpoint-down(lg){
                margin-top: 0;
            }
        }

    }
}

