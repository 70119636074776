@use "../../componenets/typography";
@use '../../customs' as *;
@use '../../componenets/mixins'as *;
@use '../../componenets/animations'as *;


.intro-section{
    @include gradient;
    padding: 10rem 0 0 0;
    width: 100%;
    height:100%;
}

.intros{


    .video-box{
        position: relative;
        a{
            // @include absoluteCenter;
            span{

                i{
                    font-size: 6rem;
                    color: rgba($secondary,0.5)
                }
            }
        }
    }
}

.border-animation {
    position: absolute;
    top:0;
    left: 0;
    width: 6rem;
    height: 6rem;
    border: 0.55rem solid $white;
    border-radius: 50%;
    animation: pulse-border 1.5s linear infinite;
    &--border-1{
        animation-delay: 1s;
    }

    &--border-2{
        animation-delay: 1.5s;
    }
}