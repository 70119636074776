@use '../../customs' as *;
@use '../../componenets/mixins'as *;
@use '../../componenets/buttons';
@use '../../componenets/typography';
@import '../../fontawesome.scss';

.collections{
    position: relative;
    padding: 1rem 0;

    &__content{
        padding: 1rem;
        margin-top: 0rem;
        @include media-breakpoint-up(lg){
            margin-top: 5rem;
        }
        i{
            font-size: 1.5rem;
            color: $primary;
            @include media-breakpoint-up(lg){
                font-size: 2.5rem;
            }
        }
    }
    &__pic{
        max-width: 100%;        
    }
}