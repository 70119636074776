@use "../../componenets/typography";
@use '../../customs' as *;
@use '../../componenets/mixins'as *;
@use '../../componenets/animations'as *;
@import '../../fontawesome.scss';

.get-started{
    padding: 4rem 0;
    background-color: $gray-200;
}
.gradient{
    background:linear-gradient(to right,rgba($primary,0.95),rgba($secondary,0.95));
}

.cta-info{
    padding: 3rem;
    margin: 1rem 0;
    &__list{
        position: relative;
        list-style-type: none;
        display: block;
        padding-top: 0.5rem;
        padding-left: 0.5rem;
        margin-bottom: 0.5rem;
        li{
            i{
                margin-right: .5rem;
                font-weight: 600;
                font-size: 1.2rem;
            }
        }
    }
}