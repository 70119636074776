//MY THEME COLORS
$purple-dark:  #9926f0 ;
$purple-light:  #BB6EF5 ;
$pink:    #D122E3 ;
$footer: #151414;



//WHITE AND GRAY COLORS
$white:    #fff ;
$gray-100: #f8f9fa ;
$gray-200: #e9ecef ;
$gray-300: #dee2e6 ;
$gray-400: #ced4da ;
$gray-500: #adb5bd ;
$gray-600: #6c757d ;
$gray-700: #495057 ;
$gray-800: #343a40 ;
$gray-900: #212529 ;
$black:    #000 ;

$primary:       $purple-dark;
$secondary:     $pink;


//ACCORDIAN OVERRIDES
$accordion-icon-color:                    $primary;
$accordion-icon-active-color:             $secondary;

$accordion-padding-y:                     1.5rem;
$accordion-padding-x:                     1.5rem;

$accordion-button-icon:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/><path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/></svg>") ;


$accordion-button-active-icon:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color}'><path fill-rule='evenodd' d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/><path d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/></svg>") ;




// IMPORT BOOTSTRAP
@import "../node_modules/bootstrap/scss/bootstrap.scss"
