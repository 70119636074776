//PULSE BORDER

@keyframes pulse-border{
    0%{
        transform: scale(1);
        opacity: 1;
    }

    100%{
        transform: scale(1.5);
        opacity: 0;
    }
}