@use '../customs' as *;

//GRADIENT MIXIN

@mixin gradient{
    background:linear-gradient(to right,rgba($primary,0.95),rgba($secondary,0.95));
}

//ABSOLUTE CENTER

@mixin absoluteCenter{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}